import { useRouter } from 'next/router';
import Head from 'next/head';
import T_LOCALES from '@/config/translations/languages.mjs';
import { generateCanonicalUrl } from '@/helpers/routing';
import { checkIfLanguagesDiffer } from '@/helpers/language';

const PageLocaleLinks = () => {
  const {
    asPath,
    locale
  } = useRouter();

  return (
    <Head>
      {
        T_LOCALES
          .filter((l) => checkIfLanguagesDiffer({
            lang: l,
            otherLang: locale
          }))
          .map((l) => (
            <link
              href={ generateCanonicalUrl({
                locale: l,
                asPath
              }) }
              hrefLang={ l }
              key={ l }
              rel='alternate'
            />
          ))
      }
    </Head>
  );
};

export default PageLocaleLinks;