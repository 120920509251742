/* eslint @next/next/no-img-element: "off" */
import Head from 'next/head';

export const CONTENT_STRING = 'height=device-height,width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no';

const BaseMetaData = () => (
  <Head>
    { /* Icons */ }
    <link href='/static/icons/apple-touch-icon.png' rel='apple-touch-icon' sizes='180x180' />
    <link
      href='/static/icons/favicon-32x32.png' rel='icon' sizes='32x32'
      type='image/png'
    />
    <link
      href='/static/icons/favicon-16x16.png' rel='icon' sizes='16x16'
      type='image/png'
    />
    <link href='/static/icons/site.webmanifest' rel='manifest' />
    <link color='#000000' href='/static/icons/safari-pinned-tab.svg' rel='mask-icon' />
    <link href='/static/icons/favicon.ico' rel='shortcut icon' />
    <meta content='#000000' name='msapplication-TileColor' />
    <meta content='/static/icons/browserconfig.xml' name='msapplication-config' />
    <meta content='#000000' name='theme-color' />
    { /* Fonts */ }
    <link
      as='font'
      crossOrigin='anonymous'
      href='/static/fonts/MaisonNeue-Book.woff'
      rel='preload'
    />
    <link
      as='font'
      crossOrigin='anonymous'
      href='/static/fonts/MaisonNeue-Medium.woff2'
      rel='preload'
    />
    <link
      as='font'
      crossOrigin='anonymous'
      href='/static/fonts/MaisonNeue-Bold.woff2'
      rel='preload'
    />
    { /* Quantcast */ }
    <noscript>
      <div style={ { display: 'none' } }>
        <img
          alt='Quantcast'
          border='0'
          height='1'
          src='//pixel.quantserve.com/pixel/p-KpV2zKBP2-9bK.gif'
          width='1'
        />
      </div>
    </noscript>
    { /* Standard */ }
    <meta content={ CONTENT_STRING } key='viewport' name='viewport' />
    <meta content='text/html; charset=utf-8' httpEquiv='Content-Type' key='content-type' />
  </Head>
);

export default BaseMetaData;