import {
  useEffect, useRef, useState
} from 'react';

const useNotifications = ({
  // An array of notifications
  notifications,
  // A function used to clear the animation: (notification) => { ... }
  clearNotification,
  // A function used return the unique key for storing the active state
  generateNotificationKey,
  // Boolean for deciding whether animations should happen concurrently or not
  concurrentAnimation,
  // The duration that the notification should be displayed on screen
  notificationDuration,
  // The unmount delay for the notification exiting the screen.
  // Should be equal to or greater than any exit animation time
  unmountDelay = 1000
}) => {
  const notificationTimeout = useRef(null);

  // Use this ref to hold the next notification. This is stored in a ref
  // to make sure we always never reference a stale value
  const nextRef = useRef({});

  // This state object holds falsey, 'active' or 'finished' for each notification id
  // representing whether it should use a default class, active class or finished class
  // This allows transitioning of the notifications
  const [active, setActive] = useState({});

  // The current notification to display is at the top of the notifications array
  const currentNotification = notifications[0] || {};
  const { id: currentId } = currentNotification;

  // The next notification to follow - it can fade in whilst the current fades out
  const nextNotification = notifications[1] || {};
  const { id: nextId } = nextNotification;

  // Whenever the next id changes, update this in the ref
  useEffect(() => {
    nextRef.current = nextNotification || {};
    // eslint-disable-next-line
  }, [nextId]);

  // Effect for handling active states and clearing notifications
  useEffect(() => {
    // We only need to perform actions if the current notification id exists
    if (currentId && currentId.toString()) {
      // Generate the unique key for the current notification
      const currentKey = generateNotificationKey(currentNotification);

      // Mark the current notification as active
      setTimeout(() => {
        setActive({
          ...active,
          [currentKey]: 'active'
        });
      }, 0);
  
      // After the notification duration
      setTimeout(() => {
        const next = nextRef.current;
        // Generate the unique key for the next notification
        const nextKey = generateNotificationKey(next);
        
        setActive({
          ...active,
          // Mark the current notification as finised
          [currentKey]: 'finished',
          // If there is a next notification and concurrency is enabled,
          // Mark the next notifcation as active so it will transition in
          // Otherwise it will become active when it moves to the top of the queue
          ...((next.id && concurrentAnimation) ? { [nextKey]: 'active' } : {})
        });
      }, notificationDuration);
  
      // After the unmount delay (when the current notification has transitioned out),
      // clear the notification from the queue
      notificationTimeout.current = setTimeout(() => {
        clearNotification(currentNotification);
      }, notificationDuration + unmountDelay);
    }
    // eslint-disable-next-line
  }, [currentId]);

  const forceRemoveCurrentNotification = () => {
    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }

    clearNotification(currentNotification);
  };

  return {
    active,
    forceRemoveCurrentNotification
  };
};

export default useNotifications;