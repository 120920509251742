import useSEO from '../hooks/useSEO';
import BaseSharingMetaData from './BaseSharingMetaData';
import FacebookSharingMetaData from './FacebookSharingMetaData';
import TwitterSharingMetaData from './TwitterSharingMetaData';

const SharingMetaData = () => {
  const { disableSharingTags } = useSEO();

  return disableSharingTags
    ? null
    : (
      <>
        <BaseSharingMetaData />
        <FacebookSharingMetaData />
        <TwitterSharingMetaData />
      </>
    );
};

export default SharingMetaData;