import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Provider } from './context';
import { generateCanonicalUrl } from '@/helpers/routing';
import { createPageTitle } from '@/helpers/seo/common';
import { createDiscoverPageDescription } from '@/helpers/seo/discover';
import { DEFAULT_SHARING_IMAGE } from '@/config/sharing-links';

const SEOProvider = ({
  children,
  title,
  sharingTitle,
  description,
  sharingImage,
  sharingDescription,
  twitterUrl,
  disableSharingTags,
  disableIndexing,
  useTitleRaw,
  structuredData
}) => {
  const { asPath, locale } = useRouter();
  const formattedUrl = generateCanonicalUrl({
    asPath,
    locale
  });
  const formattedTitle = useTitleRaw ? title : createPageTitle(title);
  const formattedDescription = description || createDiscoverPageDescription();

  const value = useMemo(() => ({
    title: formattedTitle,
    sharingTitle: sharingTitle || formattedTitle,
    url: formattedUrl,
    description: formattedDescription,
    sharingDescription: sharingDescription || formattedDescription,
    sharingImage: sharingImage || DEFAULT_SHARING_IMAGE,
    twitterUrl,
    disableSharingTags,
    disableIndexing,
    structuredData
  }), [
    sharingTitle,
    formattedTitle,
    formattedUrl,
    sharingDescription,
    formattedDescription,
    sharingImage,
    twitterUrl,
    disableSharingTags,
    disableIndexing,
    structuredData
  ]);

  return <Provider value={ value }>{ children }</Provider>;
};

SEOProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  sharingTitle: PropTypes.string,
  description: PropTypes.string,
  sharingDescription: PropTypes.string,
  sharingImage: PropTypes.string,
  twitterUrl: PropTypes.string,
  disableSharingTags: PropTypes.bool,
  disableIndexing: PropTypes.bool,
  useTitleRaw: PropTypes.bool,
  structuredData: PropTypes.arrayOf(PropTypes.object)
};

SEOProvider.defaultProps = {
  title: '',
  sharingTitle: '',
  description: '',
  sharingDescription: '',
  sharingImage: '',
  twitterUrl: '',
  disableSharingTags: false,
  disableIndexing: false,
  useTitleRaw: false,
  structuredData: []
};

export default SEOProvider;