import { connect } from 'react-redux';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Notification, { HighlightedNotificationText } from './Notification';
import useNotifications from '@/hooks/utility/useNotifications';
import { clearAllNotifications, removeNotification } from '@/state/notifications/actions';
import { selectNotifications } from '@/state/selectors/notifications';
import CONFIG from '@/config/global';
import pageKeys from '@/helpers/routing/constants';

const { notificationDuration } = CONFIG;

const concurrentAnimation = true;

const unmountDelay = 300;

const Notifications = ({
  notifications,
  dispatch
}) => {
  const [clearingNotifications, setClearingNotifications] = useState(false);

  const handleClearNotification = (notification) => dispatch(removeNotification(notification.id));

  const generateNotificationKey = (notification) => (notification.id + notification.type);

  const { active, forceRemoveCurrentNotification } = useNotifications({
    notifications,
    clearNotification: handleClearNotification,
    generateNotificationKey,
    concurrentAnimation,
    notificationDuration,
    unmountDelay
  });

  const router = useRouter();

  return notifications.map((n) => {
    const {
      id,
      title,
      assets,
      success,
      type
    } = n;

    let text = '';

    if (type.includes('library')) {
      if (success) {
        text = type.includes('add') ? 'Added to library' : 'Removed from library';
      } else {
        text = type.includes('add') ? 'Failed to add to library' : 'Failed to remove from library';
      }
    }

    if (type.includes('queue')) {
      if (success) {
        text = type.includes('add') ? 'Added to queue' : 'Removed from queue';
      } else {
        text = type.includes('add') ? 'Failed to add to queue' : 'Failed to remove from queue';
      }
    }

    if (!text) {
      return null;
    }

    const activeState = active[generateNotificationKey(n)] || null;

    const handleClear = (clearType = 'current') => {
      setClearingNotifications(true);

      const action = (clearType === 'all')
        ? () => dispatch(clearAllNotifications())
        : forceRemoveCurrentNotification;

      setTimeout(() => {
        action();
        setClearingNotifications(false);
      }, unmountDelay);
    };

    const handleClickNotification = () => {
      if (type.includes('library')) {
        router.push(pageKeys.library);
      } else if (type.includes('queue')) {
        // Do not yet handle this case
      }

      handleClear('current');
    };

    return (
      <Notification
        activeState={ !clearingNotifications ? activeState : 'finished' }
        assets={ assets }
        handleClick={ handleClickNotification }
        key={ id }
        text={ text }
        title={ title }
      />
    );
  });
};

Notifications.propTypes = { notifications: PropTypes.array.isRequired };

// ------ Connect the page with the store and inject required state as props ------
const mapStateToProps = (state) => ({ notifications: selectNotifications(state) });

export default connect(mapStateToProps)(Notifications);
