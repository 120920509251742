import { useCallback, useMemo } from 'react';
import sortBy from 'lodash.sortby';
import omit from 'lodash.omit';
import {
  forEach, keyBy, map
} from 'lodash';
import useQuery from '@/react-query/common/hooks/useQuery';
import { QK_GENRES_PARENTS, QK_GENRES_ALL } from '@/config/query-keys';
import { formatGenre } from '@/helpers/pages/genres';
import getGenres from '@/helpers/api/genres/getGenres';

// API genre types
export const GENRES_API_TYPE_ALL = 'all';
export const GENRES_API_TYPE_PARENTS = 'parents';

// Local genre structured formats
export const GENRES_PARENTS = 'genres-parents-without-nested-children';
export const GENRES_PARENTS_WITH_NESTED_CHILDREN = 'genres-parents-with-nested-children';
export const GENRES_ALL_FLATTENED = 'genres-all-flattened';

const keyGenresById = (genres) => keyBy(
  sortBy(genres, (g) => g.title),
  'id'
);

const formatGenres = (genres) => keyGenresById(
  (genres || []).map((g) => formatGenre(g))
);

const formatAllGenres = (genres) => {
  let genresTree = [];

  forEach(genres, (g) => {
    if (
      g.is_parent
      || (!g.is_parent && !g.parent) // Push orphaned children
    ) {
      genresTree.push(g);
    }
  });
  
  genresTree = genresTree.map((parentGenre) => {
    const children = [];

    forEach(genres, (g) => {
      if (g.parent && (g.parent.id === parentGenre.id)) {
        children.push(g);
      }
    });

    return {
      ...parentGenre,
      children: sortBy(children, (g) => g.title)
    };
  });

  const sortedGenresTree = keyGenresById(
    genresTree
  );

  return sortedGenresTree;
};

const flattenGenres = (genres) => {
  let allGenresFlattened = map(genres || [], (genre) => {
    return omit(genre, ['children']);
  });

  // Merge child genres with the parents in the same array
  forEach(genres, (genre) => {
    allGenresFlattened = [
      ...allGenresFlattened,
      ...(genre.children || [])
    ];
  });

  return keyGenresById(
    allGenresFlattened
  );
};

const useGenres = ({
  enabled = true,
  type = null
} = {}) => {
  const flattenAll = (type === GENRES_ALL_FLATTENED);
  const fetchAll = (type === GENRES_PARENTS_WITH_NESTED_CHILDREN) || flattenAll;

  const handleGetAllGenres = useCallback(() => getGenres({ filter: GENRES_API_TYPE_ALL }), []);
  const handleGetParentGenres = useCallback(() => getGenres({ filter: GENRES_API_TYPE_PARENTS }), []);
  
  const results = useQuery({
    queryKey: [fetchAll ? QK_GENRES_ALL : QK_GENRES_PARENTS],
    queryFn: fetchAll ? handleGetAllGenres : handleGetParentGenres,
    enabled,
    staleTime: 1000 * 60 * 60 * 24 // 24 hours
  });

  const { data } = results;

  const {
    json,
    notFound,
    status: statusCode
  } = data || {};

  const { nodes: genres } = json || {};

  const genresFormatted = useMemo(() => formatGenres(genres), [genres]);
  const allGenresFormatted = useMemo(() => formatAllGenres(genresFormatted), [genresFormatted]);
  const allGenresFlattened = useMemo(() => (fetchAll ? flattenGenres(allGenresFormatted) : {}), [allGenresFormatted, fetchAll]);
  const allGenres = (flattenAll ? allGenresFlattened : allGenresFormatted);
  const genresAsObject = fetchAll ? allGenres : genresFormatted;
  const genresAsArray = useMemo(() => map(genresAsObject), [genresAsObject]);

  return {
    ...results,
    notFound,
    statusCode,
    genres: fetchAll ? allGenres : genresFormatted,
    genresAsArray
  };
};

export default useGenres;