import { useEffect, useState } from 'react';
import CONFIG from '@/config/global';
import { getBrowserSize, getScreenSizeFlags } from '@/helpers/layout';
import useWindowSize from '@/hooks/browser/useWindowSize';

const useScreenSizeFlags = () => {
  const [shouldRender, setShouldRender] = useState(getScreenSizeFlags({ isServer: true }));
  
  const { width } = useWindowSize({ debounceTime: CONFIG.layoutShiftDebounceMs });
  const browserSize = getBrowserSize(width);

  useEffect(() => {
    setShouldRender(getScreenSizeFlags());
  }, [browserSize]);

  return shouldRender;
};

export default useScreenSizeFlags;