import Head from 'next/head';
import useSEO from '../../hooks/useSEO';

const BaseSharingMetaData = () => {
  const {
    url,
    sharingDescription,
    sharingTitle,
    sharingImage
  } = useSEO();

  return (
    <Head>
      <meta
        content={ sharingTitle }
        property='og:title'
      />
      <meta
        content='Keakie'
        property='og:site_name'
      />
      { sharingImage && (
        <>
          <meta
            content={ sharingImage }
            property='og:image'
          />
          <meta
            content='1200'
            property='og:image:width'
          />
          <meta
            content='628'
            property='og:image:height'
          />
        </>
      ) }
      <meta
        content={ sharingDescription }
        property='og:description'
      />
      <meta
        content='website'
        property='og:type'
      />
      <meta
        content={ url }
        property='og:url'
      />
    </Head>
  );
};

export default BaseSharingMetaData;