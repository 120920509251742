import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, GET_GENRES } } = CONFIG;

// Get a list of genres by filter and/or parent id
// filter: eg 'all' or 'parents'
// parentId: id of the parent to find children genres for
const getGenres = ({
  filter = null,
  parentId = null,
  isServer = false
} = {}) => {
  return callApi({
    url: HOST(GET_GENRES) + GET_GENRES + (filter ? `/${ filter }` : ''),
    queryParams: { ...(parentId ? { parent_id: parentId } : {}) },
    isServer
  });
};

export default getGenres;