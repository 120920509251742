import { useContext } from 'react';
import MainScrollElementContext from '@/contexts/main-scroll-element';
import useMounted from '@/hooks/utility/useMounted';

const useMainScrollElement = ({ notifyRefAttached = false } = {}) => {
  // This ensures that the scroll element ref is updated after mount
  useMounted({ updateState: notifyRefAttached });

  const value = useContext(MainScrollElementContext);

  return value;
};

export default useMainScrollElement;