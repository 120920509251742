import { isBrowser } from './browser';
import { MAIN_SCROLL_ELEMENT_ID } from '@/config/layout';

// Get root scroll element
export const getRootScrollElement = () => {
  return isBrowser()
    ? document.getElementById(MAIN_SCROLL_ELEMENT_ID)
    : null;
};

// Scrolls the app content container to a certain x/y position
export const scrollTo = (options, element = null) => {
  if (isBrowser()) {
    const elementToScroll = element || getRootScrollElement();

    if (elementToScroll) {
      // Scroll the app content to the x and y position
      elementToScroll.scrollTo(options);
    }
  }
};

// Returns the current Y scroll position of the passed element.
// If no element is passed then it returns the scroll of the main scroll container
export const getElementScroll = (element) => {
  const scrollElement = element || getRootScrollElement();

  if (!scrollElement) {
    return {};
  }

  const position = scrollElement.scrollTop;
  const scrollY = position || 0;
  const scrollHeight = scrollElement && scrollElement.scrollHeight;
  const clientHeight = scrollElement && scrollElement.clientHeight;
  const distanceToScrollEnd = (scrollHeight && clientHeight)
    ? Math.abs(Math.abs(scrollHeight - scrollY) - clientHeight)
    : 30000;

  return {
    scrollY,
    scrollHeight,
    clientHeight,
    distanceToScrollEnd
  };
};