import PropTypes from 'prop-types';
import { BP_WIDTH_KEY_SMALL_MOBILE, BP_WIDTH_KEY_SMALL_MOBILE_OR_MOBILE } from '@/config/breakpoints';
import { SMALL } from '@/config/assets';
import { classes } from '@/helpers/styling';
import useScreenSizeFlags from '@/hooks/layout/useScreenSizeFlags';
import Text from '@/components/component-library/Text';
import SquareImage from '@/components/component-library/Image/KeakieImageSquare';
import Span from '@/components/component-library/Span';

export const HighlightedNotificationText = ({ children, highlight }) => (
  <Span className={ classes('notification-destination-text', { '--is-highlighted': highlight }) }>
    { children }
  </Span>
);

HighlightedNotificationText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  highlight: PropTypes.bool
};

HighlightedNotificationText.defaultProps = { highlight: false };

const Notification = ({
  activeState,
  handleClick,
  assets,
  title,
  actionText,
  destinationText,
  highlightDestinationText,
  text
}) => {
  const { [BP_WIDTH_KEY_SMALL_MOBILE]: isSmallMobile, [BP_WIDTH_KEY_SMALL_MOBILE_OR_MOBILE]: isMobile } = useScreenSizeFlags();

  const headerOffset = isMobile ? 16 : 32;

  let className = 'mobile-notification';
  let style = { transform: 'translateY(-100%)' };
  
  if (activeState === 'active') {
    className = 'mobile-notification --is-active';
    style = { transform: `translateY(${ headerOffset }px)` };
  }
  if (activeState === 'finished') {
    className = 'mobile-notification --is-finished';
  }

  const notificationText = (
    <Text
      className='action-text'
      fixed
      useHeaderFont
    >
      {
        text || (
          <>
            { actionText }
            <HighlightedNotificationText highlight={ highlightDestinationText }>{ ` ${ destinationText }` }</HighlightedNotificationText>
          </>
        )
}
    </Text>
  );

  return (
    <div className='notification'>
      <div
        className={ className }
        onClick={ handleClick }
        style={ style }
      >
        { !isSmallMobile && (
          <div className='episode-image'>
            <SquareImage
              assetSize={ SMALL }
              assetsObj={ assets }
              className='episode-image'
            />
          </div>
        ) }
        { isSmallMobile ? (
          <div className='episode-information small-notification-text'>
            { notificationText }
          </div>
        ) : (
          <div className='episode-information'>
            <Text
              bold
              className='episode-name'
              fixed
              useHeaderFont
            >
              { title }
            </Text>
            { notificationText }
          </div>
        ) }
      </div>
    </div>
  );
};

Notification.propTypes = {
  activeState: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  assets: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  destinationText: PropTypes.string.isRequired,
  highlightDestinationText: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ])
};

Notification.defaultProps = {
  activeState: false,
  highlightDestinationText: false,
  text: undefined
};

export default Notification;