/* eslint-disable react/no-danger */
import Head from 'next/head';
import useSEO from '../hooks/useSEO';
import { isBrowser } from '@/helpers/browser';

let DOMPurify;

if (isBrowser()) {
  const { default: createDOMPurify } = await import('dompurify') || {};
  
  if (createDOMPurify) {
    DOMPurify = createDOMPurify(window);
  }
}

const StructuredData = () => {
  const { structuredData } = useSEO();

  if (!DOMPurify) {
    return null;
  }

  const makeJSONLD = (object) => ({ __html: DOMPurify.sanitize(JSON.stringify(object)) });

  return (
    <Head>
      { structuredData.map(
        ({ id, data } = {}, index) => ((id && data)
          ? (
            <script
              dangerouslySetInnerHTML={ makeJSONLD(data) }
              key={ id || index }
              type='application/ld+json'
            />
          )
          : null
        )
      ) }
    </Head>
  );
};

export default StructuredData;