import { useCallback, useEffect } from 'react';

const message = 'Leaving the page will interrupt audio playback. Are you sure you want to leave?';

const useWarnLeavePage = ({ isPaused: paused, episodeId }) => {
  const handleBeforeUnload = useCallback((e) => {
    e.preventDefault();

    e.returnValue = message;

    return message;
  }, []);

  useEffect(() => {
    if (episodeId && !paused) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [episodeId, paused, handleBeforeUnload]);
};

export default useWarnLeavePage;