import {
  useEffect, useRef, useState
} from 'react';

const useMounted = ({ updateState = false } = {}) => {
  const mounted = useRef(false);
  const [, setMountedState] = useState(false);

  useEffect(() => {
    mounted.current = true;
    if (updateState) {
      setMountedState(true);
    }
  }, [updateState]);

  return mounted.current;
};

export default useMounted;