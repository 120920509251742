import { useEffect, useState } from 'react';
import { localStorageExists } from '@/helpers/localStorage';

const useCheckLocalStorage = () => {
  const [localStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [localStorageChecked, setLocalStorageChecked] = useState(false);

  useEffect(() => {
    const exists = localStorageExists();
    setLocalStorageChecked(true);
    setLocalStorageAvailable(exists);
  }, []);

  return {
    localStorageChecked,
    localStorageAvailable,
    showCookieError: localStorageChecked && !localStorageAvailable
  };
};

export default useCheckLocalStorage;