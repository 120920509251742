import * as layoutValues from '../sass/variables/layout-dimensions.module.scss';

export const MAIN_SCROLL_ELEMENT_ID = 'app-content-container';

// KEYS
export const HEADER_HEIGHT_KEY_MOBILE = 'var-header-height-mobile';
export const HEADER_HEIGHT_KEY_DESKTOP = 'var-header-height-desktop';
export const FOOTER_HEIGHT_KEY_DESKTOP = 'var-footer-height-desktop';
export const FOOTER_HEIGHT_KEY_MOBILE = 'var-footer-height-mobile';
export const PAGE_PADDING_KEY_TOP_DESKTOP = 'var-page-padding-top-desktop';
export const PAGE_PADDING_KEY_BOTTOM_DESKTOP = 'var-page-padding-bottom-desktop';
export const PAGE_PADDING_KEY_TOP_MOBILE = 'var-page-padding-top-mobile';
export const PAGE_PADDING_KEY_BOTTOM_MOBILE = 'var-page-padding-bottom-mobile';

// VALUES
export const HEADER_HEIGHT_MOBILE = Number(layoutValues[HEADER_HEIGHT_KEY_MOBILE]);
export const HEADER_HEIGHT_DESKTOP = Number(layoutValues[HEADER_HEIGHT_KEY_DESKTOP]);
export const FOOTER_HEIGHT_DESKTOP = Number(layoutValues[FOOTER_HEIGHT_KEY_DESKTOP]);
export const FOOTER_HEIGHT_MOBILE = Number(layoutValues[FOOTER_HEIGHT_KEY_MOBILE]);
export const PAGE_PADDING_TOP_DESKTOP = Number(layoutValues[PAGE_PADDING_KEY_TOP_DESKTOP]);
export const PAGE_PADDING_BOTTOM_DESKTOP = Number(layoutValues[PAGE_PADDING_KEY_BOTTOM_DESKTOP]);
export const PAGE_PADDING_TOP_MOBILE = Number(layoutValues[PAGE_PADDING_KEY_TOP_MOBILE]);
export const PAGE_PADDING_BOTTOM_MOBILE = Number(layoutValues[PAGE_PADDING_KEY_BOTTOM_MOBILE]);

const LAYOUT_VALUES = {
  [HEADER_HEIGHT_KEY_MOBILE]: HEADER_HEIGHT_MOBILE,
  [HEADER_HEIGHT_KEY_DESKTOP]: HEADER_HEIGHT_DESKTOP,
  [FOOTER_HEIGHT_KEY_DESKTOP]: FOOTER_HEIGHT_DESKTOP,
  [FOOTER_HEIGHT_KEY_MOBILE]: FOOTER_HEIGHT_MOBILE,
  [PAGE_PADDING_KEY_TOP_DESKTOP]: PAGE_PADDING_TOP_DESKTOP,
  [PAGE_PADDING_KEY_BOTTOM_DESKTOP]: PAGE_PADDING_BOTTOM_DESKTOP,
  [PAGE_PADDING_KEY_TOP_MOBILE]: PAGE_PADDING_TOP_MOBILE,
  [PAGE_PADDING_KEY_BOTTOM_MOBILE]: PAGE_PADDING_BOTTOM_MOBILE
};

export default LAYOUT_VALUES;