import { useCallback, useEffect } from 'react';

// Disables scroll on an element if shouldDisable is set to true
// Default element is the the whole document
const useDisableRightClick = ({ element, shouldDisable = true } = {}) => {
  const overrideRightClick = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    const disabledElement = element || document;

    if (shouldDisable) {
      disabledElement.addEventListener('contextmenu', overrideRightClick);
    }

    return () => {
      disabledElement.removeEventListener('contextmenu', overrideRightClick);
    };
    // eslint-disable-next-line
  }, [element, shouldDisable]);

  return null;
};

export default useDisableRightClick;