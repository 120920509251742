import { connect } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Meta from '@/components/app/Meta';
import ErrorPage from '@/components/app/Error/ErrorFullPage';
import NotFoundPage from '@/components/app/Error/NotFoundFullPage';
import Notifications from '@/components/app/Notifications';
import { classes } from '@/helpers/styling';
import { scrollTo } from '@/helpers/app-layout';
import { selectAudioPaused, selectCurrentEpisode } from '@/state/selectors/audio';
import useAppFilter from '@/hooks/layout/useAppFilter';
import useCheckLocalStorage from '@/hooks/browser/useCheckLocalStorage';
import useDisableRightClick from '@/hooks/browser/useDisableRightClick';
import useMainScrollElement from '@/hooks/layout/useMainScrollElement';
import useUser from '@/hooks/api/useUser';
import useWarnLeavePage from '@/hooks/browser/useWarnLeavePage';
import { resetAccountState } from '@/state/account/actions';
import { MAIN_SCROLL_ELEMENT_ID } from '@/config/layout';
import CONFIG from '@/config/global';
import useGenres, { GENRES_PARENTS } from '@/react-query/genres/hooks/useGenres';

const Page = ({
  children,
  meta,
  header,
  notFound,
  error,
  onClickRetry,
  dispatch,
  pageId,
  currentlyPlayingEpisode,
  paused,
  contentClassName,
  headerClassName,
  enableSEOSnippet,
  hideHeader,
  disableDataFetching
}) => {
  // Check whether the app is filtered/blurred. Content is is blurred when opening overlays/menus
  const { appIsFiltered } = useAppFilter();
  // Ref to attach to the main scroll element
  const { ref } = useMainScrollElement();
  // Check for failed login. Certain page elements are hidden from the user if login fails
  const { failedLogin } = useUser();
  // Check for availability of local storage. If disabled, the app is not usable as cookies are necessary
  const { showCookieError } = useCheckLocalStorage();
  // Extract the currently playing episode id
  const episodeId = currentlyPlayingEpisode?.episode?.id;
  // Warn the user when leaving the page (only if music is currently playing)
  useWarnLeavePage({
    isPaused: paused,
    episodeId
  });
  // Disable the default right-click functionality
  useDisableRightClick({ shouldDisable: CONFIG.disableRightClickGlobally });
  // Fetch parent genres
  useGenres({
    type: GENRES_PARENTS,
    enabled: !disableDataFetching
  });
  useEffect(() => {
    scrollTo({ top: 0 });
  }, [pageId]);

  let errorPage;

  if (notFound) {
    errorPage = (
      <NotFoundPage />
    );
  } else if (showCookieError) {
    errorPage = (
      <ErrorPage
        primaryButtonConfig={{ hide: true }}
        primaryText=''
        secondaryText='Cookie heading'
        tertiaryText='Cookie subheading'
      />
    );
  } else if (failedLogin) {
    errorPage = (
      <ErrorPage
        primaryButtonConfig={{
          disableDefaultFunc: true,
          onClick: () => dispatch(resetAccountState())
        }}
        secondaryButtonConfig={{ hide: true }}
      />
    );
  } else if (error) {
    errorPage = (
      <ErrorPage
        primaryButtonConfig={{
          text: 'Retry',
          disableDefaultFunc: true,
          onClick: onClickRetry
        }}
      />
    );
  }

  const showContent = !errorPage;
  const dataNoSnippetProps= enableSEOSnippet ? {} : { 'data-nosnippet': '' };

  return (
    <>
      {meta || <Meta notFound={notFound} />}
      {errorPage}
      {showContent && !hideHeader ? (
        <>
          <Notifications />
          {!!header && (
            <div {...dataNoSnippetProps} className={classes('page-header', headerClassName)}>
              {header}
            </div>
          )}
        </>
      ) : null}
      {!hideHeader && <div {...dataNoSnippetProps} className={classes('custom-page-header', headerClassName)} id='custom-page-header' />}
      <div
        {...dataNoSnippetProps}
        className={classes(
          'page-content',
          contentClassName,
          { '--is-filtered': appIsFiltered }
        )}
        id={MAIN_SCROLL_ELEMENT_ID}
        ref={ref}
      >
        {showContent ? children : null}
      </div>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  header: PropTypes.any,
  notFound: PropTypes.bool,
  error: PropTypes.bool,
  onClickRetry: PropTypes.func,
  pageId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  currentlyPlayingEpisode: PropTypes.object,
  paused: PropTypes.bool,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  meta: PropTypes.node,
  enableSEOSnippet: PropTypes.bool,
  hideHeader: PropTypes.bool,
  disableDataFetching: PropTypes.bool
};

Page.defaultProps = {
  children: null,
  header: null,
  notFound: false,
  error: false,
  onClickRetry: undefined,
  pageId: undefined,
  currentlyPlayingEpisode: {},
  paused: true,
  contentClassName: '',
  headerClassName: '',
  meta: null,
  enableSEOSnippet: false,
  hideHeader: false,
  disableDataFetching: false
};

const mapStateToProps = (state) => ({
  currentlyPlayingEpisode: selectCurrentEpisode(state),
  paused: selectAudioPaused(state)
});

export default connect(mapStateToProps)(Page);
