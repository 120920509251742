import CONFIG from 'config/global';
import Head from 'next/head';

const FacebookSharingMetaData = () => {
  return (
    <Head>
      <meta
        content={ CONFIG.facebookAppId }
        property='fb:app_id'
      />
      <meta
        content={ CONFIG.facebookPageId }
        property='fb:pages'
      />
    </Head>
  );
};

export default FacebookSharingMetaData;