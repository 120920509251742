import { size } from '@/helpers/lodash';

export const extractParametersFromUrl = ({ query } = {}) => {
  const [slug, newParameter, ...invalidParams] = query?.genre_slug || [];

  const isViewingNewShows = (newParameter === 'new');
  const newParameterIsValid = !newParameter || isViewingNewShows;

  return {
    slug,
    isViewingNewShows: newParameter === 'new',
    notFound: !newParameterIsValid || size(invalidParams)
  };
};

// Extracts the required information from a Genre node
export const formatGenre = (genre) => {
  const { features = {}, parent } = genre || {};

  return {
    ...genre,
    parent: parent ? formatGenre(parent) : parent,
    origin: features.key_location,
    instruments: features.key_instruments
  };
};

export const getParentFromGenre = (genre) => {
  const genreParent = genre?.parent;
  const isParentGenre = !genreParent;
  const parentGenre = (isParentGenre ? null : genreParent);

  return parentGenre;
};

// Strips a parent title from a subgenre title. This allows us to avoid repetition of words.
// For example, if the parent title is Techno, and subgenre title is Minimal Techno, we return Minimal
export const subgenreTitleWithParentTitleStripped = ({ parentTitle, subgenreTitle }) => {
  const parentTitleRegex = new RegExp(`${ parentTitle }${ ' ' }|${ ' ' }${ parentTitle }`, 'ig');
  const keepOriginalTitle = !parentTitle || parentTitle.toLowerCase().includes('disco');
  
  return keepOriginalTitle ? subgenreTitle : subgenreTitle.replace(parentTitleRegex, '').trim();
};