// DISCOVER
export const QK_DISCOVER = 'discover';
export const QK_DISCOVER_ROW_SHOWS = 'discover-row-shows';
export const QK_STORIES = 'discover-stories';
export const QK_RANDOM_STORY = 'discover-random-story';

// SHOWS
export const QK_SHOW_BY_SLUG = 'show-by-slug';
export const QK_SHOWS_BY_SEED_ID = 'shows-by-seed-id';
export const QK_SHOWS_BY_GENRE_ID = 'shows-by-genre-id';
export const QK_SHOWS = 'shows';
export const QK_SHOWS_BY_SEARCH_TERM = 'shows-by-search-term';

// EPISODES
export const QK_EPISODES_BY_SHOW = 'episodes-by-show';

// EDGES
export const QK_EDGE_BY_SHOW_ID = 'edge-by-show-id';
export const QK_EDGE_BY_EPISODE_ID = 'edge-by-episode-id';

// GENRES
export const QK_GENRE_BY_SLUG = 'genre-by-slug';
export const QK_GENRES_PARENTS = 'genres-parents';
export const QK_GENRES_ALL = 'genres-all';