import { memo } from 'react';
import PropTypes from 'prop-types';
import { SEOProvider } from './context';
import SharingMetaData from './SharingMetaData';
import BaseMetaData from './BaseMetaData';
import PageMetaData from './PageMetaData';
import StructuredData from './StructuredData';
import PageLocaleLinks from './PageMetaData/PageLocaleLinks';

const Meta = ({
  title,
  sharingTitle,
  description,
  sharingImage,
  sharingDescription,
  twitterUrl,
  disableSharingTags,
  disableIndexing,
  notFound,
  useTitleRaw,
  structuredData
}) => {
  return (
    <SEOProvider
      description={ description }
      disableIndexing={ notFound || disableIndexing }
      disableSharingTags={ notFound || disableSharingTags }
      sharingDescription={ sharingDescription }
      sharingImage={ sharingImage }
      sharingTitle={ sharingTitle }
      structuredData={ structuredData }
      title={ title }
      twitterUrl={ twitterUrl }
      useTitleRaw={ useTitleRaw }
    >
      <BaseMetaData />
      <PageMetaData />
      <PageLocaleLinks />
      <SharingMetaData />
      <StructuredData />
    </SEOProvider>
  );
};

Meta.propTypes = {
  title: PropTypes.string,
  sharingTitle: PropTypes.string,
  sharingDescription: PropTypes.string,
  description: PropTypes.string,
  sharingImage: PropTypes.string,
  twitterUrl: PropTypes.string,
  disableSharingTags: PropTypes.bool,
  disableIndexing: PropTypes.bool,
  notFound: PropTypes.bool,
  useTitleRaw: PropTypes.bool,
  structuredData: PropTypes.arrayOf(PropTypes.object)
};

Meta.defaultProps = {
  title: '',
  sharingTitle: '',
  sharingDescription: '',
  description: '',
  sharingImage: '',
  twitterUrl: '',
  disableSharingTags: false,
  disableIndexing: false,
  notFound: false,
  useTitleRaw: false,
  structuredData: []
};

export default memo(Meta);