import ErrorFullPage from '@/components/app/Error/ErrorFullPage';

const NotFoundFullPage = () => {
  return (
    <ErrorFullPage
      primaryText='404'
      secondaryText='Page not found'
      tertiaryText='The requested page could not be found.'
    />
  );
};

export default NotFoundFullPage;
