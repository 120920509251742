import Head from 'next/head';
import useSEO from '../../hooks/useSEO';

const twitterUrlRegex = /https?:\/\/(www\.)?twitter\.com\/(#!\/)?@?([^/]*)/g;

const TwitterSharingMetaData = () => {
  const {
    sharingDescription,
    sharingTitle,
    sharingImage,
    twitterUrl
  } = useSEO();

  const twitterRegexMatches = (twitterUrlRegex.exec(twitterUrl) || []);
  const twitterCreator = twitterRegexMatches[twitterRegexMatches.length - 1] || '';

  return (
    <Head>
      <meta
        content='summary'
        name='twitter:card'
      />
      <meta
        content='@KeakieMusic'
        name='twitter:site'
      />
      { twitterCreator && (
        <meta
          content={ twitterCreator }
          name='twitter:creator'
        />
      ) }
      <meta
        content={ sharingTitle }
        name='twitter:title'
      />
      <meta
        content={ sharingDescription }
        name='twitter:description'
      />
      { sharingImage && (
        <meta
          content={ sharingImage }
          property='twitter:image'
        />
      ) }
    </Head>
  );
};

export default TwitterSharingMetaData;