import { shallowEqual, useSelector } from 'react-redux';
import Head from 'next/head';
import useSEO from '../hooks/useSEO';
import {
  selectCurrentEpisode, selectCurrentShow, selectAudioPaused
} from '@/state/selectors/audio';
import { generateSeasonEpisodeString } from '@/helpers/pages/channel';

const selector = (state) => ({
  playingEpisode: selectCurrentEpisode(state),
  playingShow: selectCurrentShow(state),
  isPlaying: !selectAudioPaused(state)
});

const PageMetaData = () => {
  const {
    playingEpisode,
    playingShow,
    isPlaying
  } = useSelector(selector, shallowEqual);

  const {
    title,
    description,
    url,
    disableIndexing
  } = useSEO();

  const episodeTitle = `${ generateSeasonEpisodeString({ episode: playingEpisode }) } - ${ playingShow?.title }`;
  const pageTitle = isPlaying ? episodeTitle : title;

  return (
    <Head>
      <title>{ pageTitle }</title>
      <link
        href={ url }
        rel='canonical'
      />
      <meta
        content={ description }
        name='description'
      />
      { !!disableIndexing && <meta content='noindex' name='robots' /> }
    </Head>
  );
};

export default PageMetaData;