import { useQuery } from '@tanstack/react-query';
import { getProcessEnv } from '@/helpers/env';
import { shouldCall } from '@/helpers/api';
import useUser from '@/hooks/api/useUser';
import { LOGIN_TYPE_SHADOW } from '@/config/login';

const { DISABLE_AUTHENTICATION } = getProcessEnv();

const useQueryWithChecks = (
  queryParamsObject = {},
  {
    waitForAuth = true,
    waitForCacheHydration = true
  } = {}
) => {
  const {
    keakieCheckLoginStatus,
    userStatus,
    cacheHydrationComplete,
    loginType
  } = useUser();

  const authIsComplete = DISABLE_AUTHENTICATION
    || (
      !shouldCall({ status: keakieCheckLoginStatus }).should
        && !keakieCheckLoginStatus.loading
        && ((loginType === LOGIN_TYPE_SHADOW) ? userStatus.success : (!!loginType && userStatus.success))
    );

  const { enabled, retry } = queryParamsObject;

  const isEnabled = !!(
    enabled
      && (
        waitForCacheHydration
          ? cacheHydrationComplete
          : true
      )
      && (
        waitForAuth
          ? authIsComplete
          : true
      )
  );

  return useQuery({
    ...queryParamsObject,
    enabled: isEnabled,
    retry: retry || 3
  });
};

export default useQueryWithChecks;