import { getProcessEnv } from '@/helpers/env.mjs';

const { KEAKIE_PRODUCTION_MODE } = getProcessEnv();

// PACKAGE NAMES
export const ANDROID_PACKAGE_NAME = KEAKIE_PRODUCTION_MODE ? 'com.keakie.edna' : 'com.keakie.edna.debug';
export const IOS_PACKAGE_NAME = KEAKIE_PRODUCTION_MODE ? 'com.keakie.edna' : 'com.keakie.edna';
export const IOS_APP_STORE_ID = '1579476638';

// CAMPAIGNS
export const UTM_CAMPAIGN_NAME_DOWNLOAD = 'download-link';
export const UTM_CAMPAIGN_NAME_PARTNER_PROGRAM = 'partner-program';

// MEDIUMS
export const UTM_MEDIUM_LINK = 'link';

// MEDIA TYPES
export const APPLE_CAMPAIGNS_MEDIA_TYPE = '8';

// URLS
export const DEFAULT_SHARING_IMAGE = 'https://assets.keakie.com/social_media_preview.jpg';
export const DEFAULT_REDIRECT_LOCATION = KEAKIE_PRODUCTION_MODE ? 'https://keakie.com/discover' : 'https://localhost:3002/discover';
export const FIREBASE_DOMAIN_URL_PREFIX = KEAKIE_PRODUCTION_MODE ? 'https://keakie.page.link' : 'https://keakiestaging.page.link';